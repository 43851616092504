.overall .boxes-info li {
  width: 20%;
}
.overall .boxes-info li .info-box {
  padding: 10%;
}
.overall .chart {
  margin-top: 20px;
  width: 100%;
  padding:10rem;
}
.tabs {
  display: flex;
  justify-content: space-between;
}
.tabs form {
}
.filter {
  display: flex;
  flex-grow: 3;
  margin: 1.5em;
}
.date-selection{
  max-width: 68rem;
  margin-top: 40px;
}
.date__control {
  margin-right: 2rem;
  width: 20.5rem;
  height: 40px !important;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 1rem 1rem;
}
.date__control input {
  width: 100%;
  border: 0.1rem solid #cbd4db;
  border-radius: 0.4rem;
  color: #6e7782;
  padding: 1rem 0.5rem 1rem 1.7rem;
  -webkit-transition: border-color 0.4s;
  -o-transition: border-color 0.4s;
  transition: border-color 0.4s;
}

.filter button {
  padding: 0.5rem 1.5rem;
}
.error-message {
  margin-top: 15px;
  padding-bottom: 15px;
  color: #ee0200;
  font-weight: 600;
  font-size: 1.7rem;
  text-align: center;
}
.graph-box {
  position: relative;
}
.legend-box {
  position: absolute;
  top: -50px;
  display: flex;
  margin: 20px;
}
.legend-box .legend {
  margin-right: 50px;
}

.legend-box .check_box {
  width: 15px;
  height: 15px;
  border: 1px solid grey;
  float: left;
  margin-right: 10px;
}
 .check_box_1 {
  background-color: #30db56;

  width: 15px;
  height: 15px;
}
 .check_box_2 {
  background-color: #e51a40;
  width: 15px;
  height: 15px;
}
 .check_box_3 {
  background-color: #a4acb7;
  width: 15px;
  height: 15px;
}

.graph-box canvas {
  margin-top: 50px;
 width:auto;
 height: auto;
 padding-right: 5px;
 margin-right: 10px;
}
