.pdfprint {
    width: 620px;   
    font-size: 12px;
    overflow: hidden;
    color: black;
    letter-spacing: 0.1px;
    font-weight: 600;
    font-family:Arial, Helvetica, sans-serif;
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .pdfprint h6{
      font-size: 18px;
  }

  .pdfprint .form__policy div{
    max-width: 620px;
    max-height:max-content;
    overflow: visible;
    white-space: pre-wrap;
    /* margin:12px 12px 15px 10px; */
  }
    
  .printcheckbox input {
    width: 1.8rem;
    height: 1.5rem;
    left: 0.8rem;
    top: 0;
    margin-right: 5px;
  }
  
  .printcheckbox label {
    font-size: 12px;
    margin-bottom: 10px;
    font-family:Arial, Helvetica, sans-serif;
    letter-spacing: 0.1px;
  }

  .pdfprint .signature-pdf {
    margin-top: 20px;
    max-width: 400px !important
  }
  .pdfprint .signature-pdf canvas{
    background-color: transparent !important
  }

  .modal-dialog{
    max-width: 720px !important;
  }
  .close{
    float: right;
    background-color: transparent;
    border: none;
  }
