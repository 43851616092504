.main__head h2 {
    margin-bottom: 2rem;
  }
  .form-search {
    max-width: 30rem;
  }
  .form-search label {
    margin-bottom: 1rem;
  }
  .form-search select {
    margin-bottom: 20px;
    padding: 10px;
  }
  .subMenuCont {
      padding: 0.9rem 2.1rem;
    border-top: 1px solid #394862;
    width: 100%;
    padding-bottom: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
  .subMenuCont p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }
  .subMenuCont p span {
    float: left;
  }
  .subMenuCont p button {
    color: #fff;
    text-decoration: none;
    opacity: 1;
    display: block;
    background-repeat: no-repeat;
    background-position: right center;
    background-color: transparent;
    border: none;
  }
  .subMenuCont p button:hover {
    color: #fff;
    text-decoration: none;
    opacity: 1;
  }
  .subMenuCont p:hover button {
    display: block;
    opacity: 1;
  }
  ul.submenu {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  ul.submenu li a {
    text-decoration: none;
    padding: 10px 0px 10px 15px;
    display: block;
    color: #fff;
    font-size: 14px;
    opacity: 0.7;
  }
  ul.submenu li a:hover,
  ul.submenu li a.active {
    background-color: #394862;
    opacity: 1;
  }
  