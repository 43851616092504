.toast-header {
  max-width: 480px;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-color: #fff;
  -webkit-box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 40px;
  left: 0px;
  z-index: 9999;
  border-left: 10px solid #30db56;
  padding: 25px 10px 25px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.toast-header-error {
  max-width: 480px;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-color: #fff;
  -webkit-box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 40px;
  left: 0px;
  z-index: 9999;
  border-left: 10px solid red;
  padding: 25px 10px 25px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.fas.fa-check-circle {
  float: left;
  font-size: 22px;
  color: #30db56;
}

.fas.fa-times-circle {
  float: left;
  font-size: 22px;
  color: red;
}

.toast-content {
  float: left;
  font-size: 16px;
  color: #262626;
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  margin-left: 10px;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right:5px;
}

.toast-close {
  font-size: 34px;
  position: relative;
  opacity: 1;
  right: 10px;
}

.toast-button {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}

/* @keyframes slide-in {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
} */
